
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "cdnURL": "https://static.myproviders.org/static/7bf16e13c69c47bb4c151dae4ffaa71a4e8db9e3",
  "version": "7bf16e13c69c47bb4c151dae4ffaa71a4e8db9e3",
  "env": "prod",
  "searchIndex": "prod-search-6",
  "apiHost": "https://api.myproviders.org",
  "webHost": "https://www.myproviders.org",
  "googleApiKey": "AIzaSyBGm_Q6afNQMn3iuwTGCMfdqJbZI2qh55I",
  "providerServiceUrl": "https://www.myproviders.org/signup?redirect_url=%2Fcreate",
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
