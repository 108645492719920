import { datadogRum } from '@datadog/browser-rum';
import { DateTime } from 'luxon';

export const useLoginWithRedirect = async (
  redirectUrl?: string,
  forceSignInFlow?: boolean,
) => {
  let paramsString = '';
  if (redirectUrl) {
    const params = new URLSearchParams();
    params.append('redirect_url', redirectUrl);
    paramsString = '?' + params.toString();
  }

  await navigateTo(forceSignInFlow ? '/signin' : '/login' + paramsString, {
    external: true,
  });
};

export const useSignupWithRedirect = async (redirectUrl?: string) => {
  let paramsString = '';
  if (redirectUrl) {
    const params = new URLSearchParams();
    params.append('redirect_url', redirectUrl);
    paramsString = '?' + params.toString();
  }

  await navigateTo('/signup' + paramsString, {
    external: true,
  });
};

export const redirectToEhrLogin = async (hasSession: boolean) => {
  const clerk = useNuxtApp().$clerk.value;
  const config = useAppConfig();

  const params = new URLSearchParams();
  params.append('redirect_url', window.location.pathname);
  params.append('force', '1');

  if (hasSession) {
    await clerk?.signOut({
      redirectUrl: `${config.webHost}/signout?${params.toString()}`,
    });
    return;
  }

  await navigateTo('/signin?' + params.toString(), {
    external: true,
  });
};

export const useRequireEhrAuth = async () => {
  const { user } = await useNuxtApp().$user;
  const userSession = useUserSession();

  const lastActivity = userSession.value?.lastActiveAt;
  const lastSignInAt = user.value?.lastSignInAt;

  console.log({
    lastActivity,
    lastSignInAt,
  });
  if (
    lastActivity &&
    DateTime.fromJSDate(lastActivity).diffNow('minutes').minutes > -60
  ) {
    console.log(
      'passed lastActivity',
      DateTime.fromJSDate(lastActivity).diffNow('minutes').minutes,
    );
    return true;
  }
  if (
    lastSignInAt &&
    DateTime.fromJSDate(lastSignInAt).diffNow('minutes').minutes > -60
  ) {
    console.log(
      'passed lastSignInAt',
      DateTime.fromJSDate(lastSignInAt).diffNow('minutes').minutes,
    );
    return true;
  }

  await redirectToEhrLogin(true);
  return false;
};

export const initUser = async () => {
  const clerk = useNuxtApp().$clerk.value;
  const user = clerk?.user;
  const mpUser = useUserSession();
  if (mpUser.value?.authId === user?.id) {
    return;
  }

  console.log('initUser', user?.id);
  if (!user) {
    mpUser.value = null;
    return;
  }

  await useFetchUser();

  if (mpUser.value && mpUser.value.isEhrEnabled) {
    await useFetchNotificationBadge();
  }
  if (user?.emailAddresses.length === 0) {
    return;
  }
  datadogRum.setUser({
    id: user?.id,
    email: getSpoofedEmail(user?.emailAddresses[0].emailAddress),
  });
};
