export default defineNuxtPlugin(async (nuxtApp) => {
  const clerk = useClerk();
  const auth = useAuth();
  const user = useUser();

  if (clerk.value?.loaded) {
    await initUser();
  }

  watch(
    () => [clerk.value?.loaded, auth.userId.value],
    async ([loaded, userId]) => {
      if (loaded) {
        await initUser();
      }
    },
  );

  return {
    provide: {
      clerk,
      auth,
      user,
    },
  };
});
