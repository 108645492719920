import revive_payload_client_8igKOolHO3 from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_5klgccxfpbqmgu4kdzfwhyk5zm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7VMwR0hodU from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_5klgccxfpbqmgu4kdzfwhyk5zm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZHZhUXT3CW from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_5klgccxfpbqmgu4kdzfwhyk5zm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_eV1gsD5YKA from "/home/runner/work/web/web/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_terser@5._3u5vnedcv3jivhvjhzneubpika/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_59gMBaUYFE from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_5klgccxfpbqmgu4kdzfwhyk5zm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8sPHKbA0lD from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_5klgccxfpbqmgu4kdzfwhyk5zm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_JGFrDN8JG9 from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_5klgccxfpbqmgu4kdzfwhyk5zm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_xgU6PqdSwP from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_5klgccxfpbqmgu4kdzfwhyk5zm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/home/runner/work/web/web/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web/web/.nuxt/components.plugin.mjs";
import prefetch_client_lf0Eme6Bxd from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_5klgccxfpbqmgu4kdzfwhyk5zm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_zNsdC8yAcM from "/home/runner/work/web/web/node_modules/.pnpm/@clerk+nuxt@1.4.0_magicast@0.3.5_react@19.0.0_vue@3.5.13_typescript@5.5.3_/node_modules/@clerk/nuxt/dist/runtime/plugin.js";
import motion_JWw8ZpQ1zN from "/home/runner/work/web/web/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.5.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import _01_datadog_client_kyKOfu6LUJ from "/home/runner/work/web/web/plugins/01.datadog.client.ts";
import _02_customFetch_client_Wfsz81JW0D from "/home/runner/work/web/web/plugins/02.customFetch.client.ts";
import clerk_client_82tfzadmlf from "/home/runner/work/web/web/plugins/clerk.client.ts";
import gesture_4SIjAfPLV5 from "/home/runner/work/web/web/plugins/gesture.ts";
export default [
  revive_payload_client_8igKOolHO3,
  unhead_7VMwR0hodU,
  router_ZHZhUXT3CW,
  _0_siteConfig_eV1gsD5YKA,
  payload_client_59gMBaUYFE,
  navigation_repaint_client_8sPHKbA0lD,
  check_outdated_build_client_JGFrDN8JG9,
  chunk_reload_client_xgU6PqdSwP,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lf0Eme6Bxd,
  plugin_zNsdC8yAcM,
  motion_JWw8ZpQ1zN,
  _01_datadog_client_kyKOfu6LUJ,
  _02_customFetch_client_Wfsz81JW0D,
  clerk_client_82tfzadmlf,
  gesture_4SIjAfPLV5
]