import type { UseFetchOptions } from 'nuxt/app';

export const useApiFetch = async <T>(
  req: string | (() => string),
  opts: UseFetchOptions<T> = {},
) => {
  return useFetch(`${useAppConfig().apiHost}${req}`, {
    ...opts,
    async onRequest({ request, options }) {
      let accessToken = null;
      if (import.meta.client) {
        const auth = useNuxtApp().$auth;
        accessToken = await auth?.getToken?.value();
      }

      if (accessToken) {
        options.headers = [['Authorization', `Bearer ${accessToken}`]];
      }
    },
    $fetch: useNuxtApp().$customFetch,
  });
};

export const useApiRequest = async <T>(
  req: string | (() => string),
  opts: UseFetchOptions<T> = {},
) => {
  let accessToken = null;
  if (import.meta.client) {
    const auth = useNuxtApp().$auth;
    accessToken = await auth?.getToken?.value();
  }

  if (!opts) {
    opts = {};
  }

  return useNuxtApp().$customFetch(`${useAppConfig().apiHost}${req}`, {
    ...opts,
    headers: accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {},
  });
};

export const useLegacyApiFetch = <T>(
  url: string | (() => string),
  options: UseFetchOptions<T> = {},
) => {
  return useFetch(url, {
    ...options,
    $fetch: useNuxtApp().$customFetch,
  });
};
