import validate from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_5klgccxfpbqmgu4kdzfwhyk5zm/node_modules/nuxt/dist/pages/runtime/validate.js";
import location_45global from "/home/runner/work/web/web/middleware/location.global.ts";
import redirect_45global from "/home/runner/work/web/web/middleware/redirect.global.ts";
import manifest_45route_45rule from "/home/runner/work/web/web/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_5klgccxfpbqmgu4kdzfwhyk5zm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  location_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}